// ** Initial State
const initialState = {
    organizations: [],
    resellers: [],
    userOrder:[],
    userPurchase:[],
    userSubscription:[],
    userTestSeries:[],
    userDetails:{}
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_ORGANIZATIONS':
            return {
                ...state,
                organizations: action.data
            }
        case 'GET_RESELLERS':
            return {
                ...state,
                resellers: action.data
            }
        case 'SET_PURCHASE_DETAILS':
            return {
                ...state,
                userOrder: action.data.userOrder,
                userPurchase: action.data.userPurchase,
                userSubscription: action.data.userSubscription,
                userTestSeries: action.data.userTestSeries,
                userDetails: action.data.userDetails
            }
        default:
            return state
    }
}

export default userReducer
