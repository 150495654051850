// ** Initial State
const initialState = {
    category: [], subCategory: [], exam: [], batchProcess: [], subscriptionPlans: [], coupanCodes:[], configs: [],
    level: [{ id: 1, name: 'Easy' }, { id: 2, name: 'Medium' }, { id: 3, name: 'Hard' }],
    type: [{ id: 1, name: 'Full Length' }, { id: 2, name: 'Sectional Test' }, { id: 3, name: 'Miscellaneous Test' }, { id: 4, name: 'BookmarkQues Test' }, { id: 5, name: 'SWOT Test' }, { id: 6, name: 'Dynamic Test' }],
    testSeries: [], tests: [], testSeriesSubscriptions: [], testPurchase: [], dynamicTests: [], examsCovered: [], previousYearTests: [], bookmarkedTests: [],
    profile: {}
}

const questionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CATEGORY':
            return {
                ...state,
                category: action.data,
            }
        case 'GET_SUBCATEGORY':
            return {
                ...state,
                subCategory: action.data
            }
        case 'GET_LEVEL':
            return {
                ...state,
                level: action.data
            }
        case 'GET_EXAM':
            return {
                ...state,
                exam: action.data
            }
        case 'BATCH_PROCESS':
            return {
                ...state,
                batchProcess: action.data
            }
        case 'SUBSCRIPTION_PLANS':
            return {
                ...state,
                subscriptionPlans: action.data
            }
        case 'COUPAN_CODES':
            return {
                ...state,
                coupanCodes: action.data
        }
        case 'TEST_CONFIG':
            return {
                ...state,
                configs: action.data
            }
        case 'GET_EXAMCOVERED':
            return {
                ...state,
                examCovered: action.data
            }
        case 'TEST_SERIES':
            return {
                ...state,
                testSeries: action.data
            }
        case 'FETCH_TESTS':
            return {
                ...state,
                tests: action.data
            }

        case 'USER_SUBSCRIPTIONS':
            return {
                ...state,
                testSeriesSubscriptions: action.data.subscription,
                testPurchase: action.data.purchase
            }

        case 'DYNAMIC_TESTS':
            return {
                ...state,
                dynamicTests: action.data
            }

        case 'BOOKMARKED_TESTS':
            return {
                ...state,
                bookmarkedTests: action.data
            }

        case 'SWOT_TESTS':
            return {
                ...state,
                swotTests: action.data
            }

        case 'EXAMS_COVERED':
            return {
                ...state,
                examsCovered: action.data
            }

        case 'EXAMS_COVERED_LIST':
            return {
                ...state,
                examsList: action.data
            }

        case 'PREVIOUS_YEAR_TESTS':
            return {
                ...state,
                previousYearTests: action.data
            }

        case 'SET_USER_PROFILE':
            console.log("vaibhav SET_USER_PROFILE", action.data)
            return {
                ...state,
                profile: action.data
            }

        default:
            return state
    }
}

export default questionReducer
